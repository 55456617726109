import React, { useState } from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../Footer";
import { Box, Typography, Divider, Button, IconButton } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import SEO from "../../../../utils/SEO";
import img1 from "../../../../Assets/images/about1.webp";
import img2 from "../../../../Assets/images/about2.webp";
import img3 from "../../../../Assets/images/about3.webp";
import img4 from "../../../../Assets/images/about4.webp";
import img5 from "../../../../Assets/images/about5.webp";

import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import FooterHero from "../FooterHero";

const About = () => {
  const [activeVision, setActiveVision] = useState(visions[0]);
  const [activeVisionMore, setActiveVisionMore] = useState(0);
  return (
    <>
      <SEO
        title="About Reisty - Connecting Diners with Premier Restaurants in Lagos"
        description="Learn more about Reisty, your premier guide to Lagos' restaurant scene. Our mission is to simplify reservations, helping you discover new dining spots and make informed decisions with ease."
        name="Reisty"
        type="article"
        canonicalUrl="https:/reisty.com/about"
      />
      <Navbar />
      <Box
        className="hide_scrollbar"
        sx={{
          pt: 15,
          px: 4,

          overflow: "scroll",
          width: { lg: "85%", md: "85%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <Typography
            component={"h1"}
            variant="h1"
            sx={{
              fontSize: {md:"17px", xs:'14px'},
              fontWeight: 200,
              color: "#c4c4c4",
              textAlign: "center",
              border: "1px solid #c4c4c4",
              width: "125px",
              py: 1,
              borderRadius: "12px",
            }}
          >
            ABOUT US
          </Typography>
        </Box>

        <Typography
          sx={{
            mt: 4,
            color: "#00000",
            fontWeight: 700,
            fontSize: {md:"50px", xs:'35px'},
            textAlign: "center",
            lineHeight: {md:"58px", xs:'48px'},
            fontFamily: "optima",
          }}
        >
          We're redefining how you dine in Lagos—Make a reservation at any
          restaurant for free.
        </Typography>
      </Box>

      <Box sx={{ display: "flex", height: "400px", mt: 8, flexDirection:{md:'row', xs:'column-reverse'} }}>
        <Box
          sx={{
            width: {md:"35%",xs:'100%'},
            background: `url(${img1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
   
          }}
        />
        <Box
          sx={{
                  width: {md:"30%",xs:'100%'},
            bgcolor: "primary.main",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ p: 4, pt: 6 }}>
            <Typography
              sx={{ color: "#fff", fontSize: "40px", fontFamily: "optima" }}
            >
              About us
            </Typography>
            <Typography
              sx={{
                mt: 3,
                color: "#fff",
                fontWeight: 300,
                fontSize: "16px",
                lineHeight: "25px",
              }}
            >
              Reisty is an online restaurant reservation service that connects
              you with various restaurants, from trendy cafes to fine dining
              establishments in Lagos. Our mission is to simplify the restaurant
              reservation process and empower diners to quickly discover, view
              menus, and read reviews before booking a restaurant table using
              our platform.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
                  width: {md:"35%",xs:'100%'},
            background: `url(${img2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
                     height:{md:'auto', xs:'200px'}
          }}
        />
      </Box>

      <Box
        className="hide_scrollbar"
        sx={{
          pt: {md:15,xs:8},
          px: 4,

          overflow: "scroll",
          width: { lg: "65%", md: "65%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <Typography
            component={"h1"}
            variant="h1"
            sx={{
              fontSize: {md:"17px", xs:'14px'},
              fontWeight: 200,
              color: "#c4c4c4",
              textAlign: "center",
              border: "1px solid #c4c4c4",
              width: "125px",
              py: 1,
              borderRadius: "12px",
            }}
          >
            OUR MISSION
          </Typography>
        </Box>

        <Typography
          sx={{
            mt: 4,
            color: "#00000",
            fontWeight: 700,
          fontSize: {md:"50px", xs:'35px'},
            textAlign: "center",
            lineHeight: {md:"58px", xs:'48px'},
            fontFamily: "optima",
          }}
        >
          Making Dining Simple with Easy Reservations
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "#717171",
            fontWeight: 300,
            fontSize: "16px",
            mt: 2,
            lineHeight: "24px",
          }}
        >
          At Reisty, we aim to revolutionize the dining experience by providing
          Reistars with a seamless and efficient platform for restaurant
          reservations and discovery. We strive to be the best reservation app
          for restaurants, offering cutting-edge reservation management tools,
          curated restaurant discovery features, and personalized
          recommendations to enhance your dining journey. Our commitment to
          excellence drives us to continuously innovate and deliver exceptional
          value to our Reistars, making us the go-to dining companion in Lagos.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          background: `url(${img3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px",
          my: 10,
        }}
      />

      <Box
        className="hide_scrollbar"
        sx={{
          pt: 5,

          width: { lg: "85%", md: "85%", sm: "90%", xs: "90%" },
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "grid", placeContent: "left" }}>
          <Typography
            component={"h1"}
            variant="h1"
            sx={{
     fontSize: {md:"17px", xs:'14px'},
              fontWeight: 200,
              color: "#c4c4c4",
              textAlign: "center",
              border: "1px solid #c4c4c4",
              width: "125px",
              py: 1,
              borderRadius: "12px",
            }}
          >
            OUR VISION
          </Typography>
        </Box>

        <Typography
          sx={{
            mt: 4,
            color: "#00000",
            fontWeight: 700,
           fontSize: {md:"50px", xs:'35px'},
         
            lineHeight: {md:"58px", xs:'48px'},
            fontFamily: "optima",
          }}
        >
          Leading the Way in Lagos' Dining <br/> Experience.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          background: `url(${img4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "550px",
          width: { lg: "85%", md: "85%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          my: 10,
          boxSizing: "border-box",
          pt: 7,
        }}
      >
        <Box
          sx={{
            bgcolor: "primary.main",
            width: {md:"40%", xs:'90%'},
            boxSizing: "border-box",
            px: 4,
            pl:{md:4, xs:6},
            py: 5,
            height: "450px",
          }}
        >
          <Typography sx={{ color: "#fff", fontWeight: 500, fontSize: "40px" }}>
            Vision
          </Typography>
          <Typography
            sx={{
              fontSize: {md:"16px", xs:'14px'},
              color: "#fff",
              fontWeight: 300,
              lineHeight: {md:"24px",xs:'19px'},
              mt: 4,
            }}
          >
            {activeVision}
          </Typography>

          <Box sx={{ mt: 2 }}>
            <IconButton
              onClick={() => setActiveVision(visions[0])}
              sx={{
                color: "#fff",
                border: "1px solid #fff",

                "&:hover": {
                  bgcolor: "#ccc",

                  color: "primary.main",
                },
              }}
            >
              <GoArrowLeft />
            </IconButton>
            <IconButton
              onClick={() => setActiveVision(visions[1])}
              sx={{
                color: "primary.main",
                bgcolor: "#fff",
                ml: 2,
                "&:hover": { bgcolor: "#ccc" },
              }}
            >
              <GoArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          background: `url(${img5})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "550px",
          display: "flex",
          justifyContent: "right",
          width: { lg: "85%", md: "85%", sm: "70%", xs: "100%" },
          margin: "0 auto",
          my: 10,
          boxSizing: "border-box",
          pt: 7,
        }}
      >
        <Box
          sx={{
            bgcolor: "primary.main",
                      width: {md:"40%", xs:'90%'},
            boxSizing: "border-box",
            px: 4,
            py: 5,
            height: "450px",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 500,
              fontSize: "35px",
              lineHeight: "40px",
            }}
          >
            {visionsList[activeVisionMore]?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#fff",
              fontWeight: 300,
              lineHeight: "24px",
              mt: 4,
            }}
          >
            {visionsList[activeVisionMore]?.details}
          </Typography>

          <Box sx={{ mt: 2 }}>
            <IconButton
              onClick={() =>
                activeVisionMore > 0 && setActiveVisionMore((prev) => prev - 1)
              }
              sx={{
                color: "#fff",
                border: "1px solid #fff",

                "&:hover": {
                  bgcolor: "#ccc",

                  color: "primary.main",
                },
              }}
            >
              <GoArrowLeft />
            </IconButton>
            <IconButton
              onClick={() => {
                if (activeVisionMore < 5) {
                  setActiveVisionMore((prev) => prev + 1);
                }
              }}
              sx={{
                color: "primary.main",
                bgcolor: "#fff",
                ml: 2,
                "&:hover": { bgcolor: "#ccc" },
              }}
            >
              <GoArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <FooterHero/>
      <Footer />
    </>
  );
};

export default About;

const visions = [
  "Our vision is to be the leading online reservation system for restaurants in Lagos, setting the benchmark for dining technology and hospitality. We aim to expand our reach, connecting Reistars with the best restaurants near them and providing them with a trusted platform to explore, book, and enjoy dining experiences. By leveraging advanced technology, data-driven insights, and a passion for culinary excellence, we aspire to transform how people dine out, making every meal a delightful celebration for our Reistars.",
  "Being a top platform for restaurant reservations, we are committed to putting foodies in touch with the ideal restaurant at their fingertips for every occasion, and we're dedicated to providing every diner we serve with culinary enjoyment, convenience, and tailored recommendations—we don't just take reservations. We also serve as:",
];

const visionsList = [
  {
    title: "Your Culinary Companion",
    details:
      "Reisty is more than just a dining platform – we're your ultimate culinary companion, dedicated to guiding you through the vibrant and diverse culinary scene of Lagos. With us, every meal becomes a delightful celebration of flavor and culture.",
  },
  {
    title: "Discover Lagos' Finest Restaurants",
    details:
      "We meticulously curate a list of the top restaurants in Lagos, ensuring you have access to the city's finest dining establishments. Whether you're planning a romantic dinner, a birthday celebration, or a professional business meeting, Reisty has the perfect spot for every occasion. Make your Reisty booking online with ease and confidence.",
  },
  {
    title: "Efficient Waitlist Optimization",
    details:
      "With our cutting-edge waitlist optimization tool, say goodbye to long wait times and hello to efficient seating management. Receive real-time updates, estimate wait times, and enhance your dining experience. Sign up for free and effortlessly streamline your waitlist management with Reisty.",
  },
  {
    title: "Luxury Dining Made Accessible",
    details:
      "With Reisty, you can enjoy top-notch dining experiences in Lagos without breaking the bank. We highlight restaurants with great menus and atmospheres, making it easy to find the perfect spot, like Restaurant C and Restaurant D.",
  },
  {
    title: "Smooth Reservation Process",
    details:
      " Booking your table has always been challenging with Reisty's comprehensive reservation guides. Whether you're a seasoned diner or a newcomer, we provide step-by-step instructions and insider tips to ensure a hassle-free booking experience every time.",
  },
  {
    title: "Seamless Mobile Experience",
    details:
      "Enjoy seamless restaurant reservations, personalized dining experiences, and more at your fingertips. Your culinary journey awaits, just a click away. Elevate your dining adventures with the Reisty app, available for download on the App Store and Google Play Store.",
  },
];
