import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type, canonicalUrl}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
  <link rel="canonical" href={canonicalUrl} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type || "website"} />
<meta property="og:url" content="https://www.reisty.com/" />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
    <meta property="og:image" content="https://res.cloudinary.com/dywbpyexo/image/upload/v1737069886/Screenshot_2025-01-17_at_12.21.42_AM_mjv2ow.png" />

{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name} />
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
<meta name="twitter:image" content={"https://res.cloudinary.com/dywbpyexo/image/upload/v1737069886/Screenshot_2025-01-17_at_12.21.42_AM_mjv2ow.png"} />
{ /* End Twitter tags */ }

   <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-3FB4WR8J6N"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-3FB4WR8J6N');
        `}
        </script>
</Helmet>
)
}