import React, { useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Footer from "../Components/Footer/Footer";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Modal,
  Avatar,
  InputLabel,
  Checkbox,
  Button,
  ListItemButton,
  MenuItem,
  Radio,
  Skeleton
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { MdCalendarMonth } from "react-icons/md";
import guestIcon from "../../Assets/icons/guest.svg";
import { AiFillClockCircle } from "react-icons/ai";
import userIcon from "../../Assets/icons/user.svg";
import { useSelector } from "react-redux";
import { BsFillInfoCircleFill } from "react-icons/bs";
import logo from "../../Assets/logo/logo-icon-red.svg";
import BookingData from "../../Assets/Dataa/BookingData";
import CustomInput from "../Components/Custom/CustomInput";
import { IoWalletOutline } from "react-icons/io5";
import { TbDeviceMobileShare } from "react-icons/tb";
import { createReservation, createReservationDiner, getReservationCost, getRestaurantDetails, getWallet } from "../../api";
import Loader from "../Components/Common/Loader";
import GuestForm from "../Components/Booking/GuestForm";
import { PaystackButton } from 'react-paystack';
import { useSnackbar } from "notistack";
import PaystackPaymentButton from "../Components/Common/PaystackPaymentButton";
import DietaryOptions from "../Components/Common/DietaryOptions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {lg:400, md:400, sm:400, xs:300},
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const Booking = () => {
      const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { restaurantName, id } = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);



  const [occasion, setOccasion] = useState("");

  const { ReservationDay, ReservationTime, PartySize, Deposit } = queryParams;
  const userDetails = useSelector((state) => state?.user?.user);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(null);

  const handleGetCost = async () => {
 if(Deposit){
  setAmount(Deposit * PartySize)
 }
  };

    const handleGetBalance = async () => {
    setIsLoading(true);
    await getWallet()
      .then((res) => {
        // console.log(res);
        setBalance(res?.data?.result?.AvailableBalance);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };



  const [guestDetails, setGuestDetails] = useState(null);
  const handleFormSubmit = (guests) => {
    setGuestDetails(guests);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Card");
  const [Dietary, setDietary] = useState("");

  const [specialRequest, setSpecialRequest] = useState("");
  const [EmailsAndOffers, setEmailsAndOffers] = useState(true);
  const [NewsAndUpdates, setNewsAndUpdates] = useState(false);
  const [useWithWalletBalance, setUseWithWalletBalance] = useState(false);

      const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };


    useEffect(()=>{
    setPhoneNumber(userDetails?.phone_number)
  }, [userDetails])


      const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState(null);

    const handleGetRestaurantDetails = async () => {
    setLoading(true);
    await getRestaurantDetails(restaurantName)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setRestaurant(res?.data?.result[0]);
    
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  console.log()

  const handleCreateReservation = async (payStackTransactionId) => {
    setIsLoading(true);




    await createReservation(
      id,
      ReservationDay,
      ReservationTime,
      PartySize,
      phoneNumber,
      amount,
      payStackTransactionId,
      guestDetails,
      occasion,
      Dietary?.join(', '),
      specialRequest,
      EmailsAndOffers,
      NewsAndUpdates,
      useWithWalletBalance
    )
    .then((res)=>{

      if(res?.data?.status){
      navigate(`/reservation/booked/${res?.data?.result[0]?.BookingId}`)
      }
      else{
           handleAlert("error", `${res?.data?.error_message}`);
      }
      setIsLoading(false)
 
    }).catch((err)=>{
      // console.log(err)
      setIsLoading(false)
                  handleAlert("error", `${err?.message}`);
    })
  };

    const handleCreateReservation2 = async () => {
    setIsLoading(true);

    await createReservationDiner(
         restaurant?.RestaurantId,
      ReservationDay,
      ReservationTime,
      PartySize,
phoneNumber,
amount,
null,
guestDetails,
occasion,
Dietary?.join(', '),
specialRequest,
EmailsAndOffers,
NewsAndUpdates,
useWithWalletBalance,
true
    )
      .then((res)=>{
      if(res?.data?.status){
      navigate(`/reservation/booked/${res?.data?.result[0]?.BookingId}`)
      }
      else{
           handleAlert("error", `${res?.data?.error_message}`);
      }
            setIsLoading(false)



    }).catch((err)=>{
      // console.log(err)
      setIsLoading(false)
            handleAlert("error", `${err?.message}`);
    })
  };

        useEffect(() => {
    handleGetBalance()
    handleGetCost();
  }, [id, restaurant]);

        useEffect(() => {
 
    handleGetCost();
  }, [Deposit]);


  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <Box sx={{ width: { xl: "75%", lg: "85%", md:'85%', sm:'90%', xs:'95%' }, margin: "0 auto", my: 15 }}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ boxSizing: "border-box" }}>
              <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "12px" }}>
                <Box sx={{ p: 3 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      borderBottom: "2px solid #BC172F",
                      width: "fit-content",
                      color: "#717171",
                    }}
                  >
                    Reservation Details
                  </Typography>

                  <Typography variant="h2" sx={{ mt: 2,fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'}, fontFamily:'optima', fontWeight:700 }}>
                    {restaurantName}
                  </Typography>
                </Box>

                <Box sx={{ borderTop: "1px solid #d7d7d7", p: 3 }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <MdCalendarMonth />
                    <Typography sx={{fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'12px'}}}>{formatDate(ReservationDay)}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      mt: 1,
                    }}
                  >
                    <img src={guestIcon} width={15} />
                    <Typography sx={{fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'12px'}}}>{PartySize} guest(s)</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      mt:1,
                    }}
                  >
                    <AiFillClockCircle style={{fontSize:'12px'}} />
                    <Typography sx={{fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'12px'}}}>{ReservationTime}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      mt: 1,
                    }}
                  >
                    <img src={userIcon}  width={15}/>
                    <Typography sx={{fontSize:{lg:'12px', md:'12px', sm:'12px', xs:'12px'}}}>{`${userDetails?.first_name} ${userDetails?.last_name}`}</Typography>
                  </Box>
                </Box>
              </Box>
           {
            amount > 0 && (
                 <Box
                sx={{
                  border: "1px solid #d7d7d7",
                  borderRadius: "12px",
                  mt: 3,
                }}
              >
                <Box sx={{ p:2 }}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'} }}
                    color="primary"
                  >
                    Reservation Deposit{" "}
                    {/* <IconButton onClick={handleOpen}>
                      <BsFillInfoCircleFill style={{ fontSize: "14px" }} />
                    </IconButton> */}
                  </Typography>
                </Box>

                <Box sx={{ borderTop: "1px solid #d7d7d7", p: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <InputLabel sx={{fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'}}}>
                      {restaurantName} * {PartySize}
                    </InputLabel>
                    <Typography sx={{ fontWeight: 500,fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'} }}>
                      ₦{amount?.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    bgcolor: "#fef5f7",
                    p: 3,
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <InputLabel sx={{ color: "#151515", fontWeight: 500, fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'} }}>
                      Total
                    </InputLabel>
                    <Typography sx={{ fontWeight: 500, fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'} }}>
                      ₦{amount?.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <InputLabel sx={{ color: "#151515", fontWeight: 500, fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'} }}>
                      Amount Due
                    </InputLabel>
                    <Typography sx={{ fontWeight: 500, fontSize: {lg:"12px", md:'12px', sm:'12px', xs:'12px'} }}>
                      ₦{amount?.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                      <Box
                      sx={{
                        p: 2,
                        bgcolor: "#bc172f1a",
                        borderRadius: "0px 0px 8px 8px",
                      }}
                    >
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                      >
                        Disclaimer
                      </Typography>
                      <Typography sx={{ fontSize: "10px" }}>
                        This deposit will be deducted from your final bill after
                        the reservation has been concluded. In the event you
                        need to cancel your reservation, please ensure you do so
                        at least 12 hours before the reservation time, and the
                        deposit will be refunded to your bank account. You will receive a reservation reminder 13 hours before your reservation time.
                      </Typography>
                    </Box>
              </Box>
            )
           }

              <Box
                sx={{
                  border: "1px solid #d7d7d7",
                  borderRadius: "12px",
                  mt: 3,
                  p: 3,
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 1}}
                >
                  <Checkbox checked={EmailsAndOffers} onChange={(e)=>setEmailsAndOffers(e.target.checked)} />
                  <InputLabel sx={{ color: "#151515", fontWeight: 500, fontSize: {sm:'12px', xs:'12px'} }}>
                    Get notifications of offers and other relevant emails from
                    this restaurant
                  </InputLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <Checkbox checked={NewsAndUpdates} onChange={(e)=>setNewsAndUpdates(e.target.checked)}/>
                  <InputLabel sx={{ color: "#151515", fontWeight: 500, fontSize: {sm:'12px', xs:'12px'} }}>
                    I would like to receive news and updates from this business.
                  </InputLabel>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #d7d7d7",
                  borderRadius: "12px",
                  mt: 3,
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Typography sx={{fontSize: {lg:"18px", md:'18px', sm:'14px', xs:'14px'} }}>
                    <b>Send an invitation to your party.</b>
                  </Typography>
                  <Typography sx={{ mt: 1, fontWeight: 300, fontSize: "12px" }}>
                    Notify your party about your reservation. They will receive
                    an email notifying them about the reservation
                  </Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #d7d7d7", mt: 2, p: {lg:3, md:3, sm:2, xs:1.5} }}>
                  <GuestForm onSubmit={handleFormSubmit} />
                </Box>
              </Box>

              <Box sx={{ mt: 5 }}>
                <Typography sx={{ fontSize: {lg:"18px", md:'18px', sm:'14px', xs:'14px'} }}>
                  <b>Lets help you plan your visit</b>
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 300, mt: 1 }}>
                  To aid The Corniche Bistro in organizing your visit, kindly
                  provide answers to the questions below.
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "12px",
                    mt: 3,
                    p: {lg:3, md:3, sm:2, xs:1.5},
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
                    What occasion are you celebrating?
                  </Typography>

                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      {BookingData?.map((item, index) => {
                        const isSelected = item?.title === occasion;
                        return (
                          <Grid item lg={4} md={4}  sm={6} xs={6} key={index}>
                            <ListItemButton
                              selected={isSelected}
                              onClick={() => setOccasion(item?.title)}
                              sx={{
                                width: "100%",
                                display: "flex",
                                columnGap: 2,
                                p: 2,
                                border: "1px solid #d7d7d7",
                                borderRadius: "8px",
                                ...(isSelected && {
                                  border: "1px solid #BC172F",
                                }),
                              }}
                            >
                              <img
                                src={isSelected ? item?.icon2 : item?.icon1}
                              />
                              <InputLabel color={isSelected && "primary"} sx={{fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>
                                {item?.title}
                              </InputLabel>
                            </ListItemButton>
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                      <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
                        Do any members of your group have specific dietary
                        restrictions?
                      </Typography>
<DietaryOptions updateDietary={(value)=>setDietary(value)}/>
                      {/* <CustomInput
                      value={Dietary}
                      onChange={(e)=>setDietary(e.target.value)}
                        placeholder="Your  input here"
                        multiline
                        fullWidth
                        margin="dense"
                        minRows={3}
                      /> */}
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
                        Do have any special request?
                      </Typography>

                      <CustomInput
                                 value={specialRequest}
                      onChange={(e)=>setSpecialRequest(e.target.value)}
                        placeholder="Your  input here"
                        multiline
                        fullWidth
                        margin="dense"
                        minRows={2}
                      />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography sx={{ fontWeight: 500 , fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>
                        Contact Number
                      </Typography>

                      <CustomInput
                        placeholder="Your  input here"
              
                                   value={phoneNumber}
                      onChange={(e)=>setPhoneNumber(e.target.value)}
                        fullWidth
                        margin="dense"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ boxSizing: "border-box" }}>
              <Box
                sx={{ border: "1px solid #d7d7d7", borderRadius: "12px", p: 3 }}
              >
                <Typography sx={{ fontWeight: 500 , fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>
                  Cancellation policy
                </Typography>
                <Typography sx={{ fontWeight: 300, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'}, mt: 1 }}>
                  We understand that circumstances can change, and if you need
                  to cancel, there won't be any charges. However, we kindly
                  request that you notify us at least 24 hours prior to your
                  scheduled time.
                </Typography>
      {
        restaurant?.HouseRules && (
          <>
                    <Typography sx={{ fontWeight: 500, mt: 3, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>House Rules</Typography>
                {
                      loading ? (
                        <>
                        <Skeleton width={"100%"} height={100} />
                        </>
                      ):(
                        <>
                          <Typography
                        sx={{ fontSize: "10px", mt: 1, textAlign: "justify" }}
                      >
               {restaurant?.HouseRules}
                      </Typography>
                        </>
                      )
                    }
                    </>
        )
      }
              </Box>

              <Typography sx={{ my: 4, textAlign: "center", fontSize: {lg:"17px", md:'17px', sm:'14px', xs:'14px', fontFamily:'optima'} }}>
                Reservation being held for{" "}
                <span style={{ color: "#BC172F" }}>
                  <b>{ReservationTime}</b>
                </span>
              </Typography>

            {
              amount > 0 && (
                <>
                  <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "12px" }}>
                <Box sx={{ p: 3 }}>
                  <Typography sx={{ fontWeight: 600, fontSize: {lg:"18px", md:'18px', sm:'16px', xs:'14px'} }}>
                    Payment Method
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderTop: "1px solid #d7d7d7",
                  }}
                >
                  <MenuItem
                    selected={paymentMethod  === "Wallet"}
                     onClick={()=>setPaymentMethod("Wallet")}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoWalletOutline />
                      <Box>
                        <Typography sx={{ fontSize: {lg:"16px", md:'16px', sm:'14px', xs:'14px'}, fontWeight:700 }}>
                          ₦{balance?.toLocaleString()}
                        </Typography>
                        <Typography sx={{ mt: 1, fontSize: {sm:'12px', xs:'12px'} }}> Wallet Balance</Typography>
                      </Box>
                    </Box>
                    <Radio checked={paymentMethod  === "Wallet"} />
                  </MenuItem>
                  <MenuItem
                  onClick={()=>setPaymentMethod("Card")}
                  selected={paymentMethod  === "Card"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      borderTop: "1px solid #d7d7d7",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <TbDeviceMobileShare />
                      <Box>
                        <Typography sx={{fontSize: {sm:'12px', xs:'12px'}}}>Pay Online</Typography>
                      </Box>
                    </Box>
                    <Radio  checked={paymentMethod  === "Card"} />
                  </MenuItem>

                  <Box
                    sx={{
                      p: 3,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Checkbox checked={useWithWalletBalance} onChange={(e)=>setUseWithWalletBalance(e.target.checked)} />
                    <Typography sx={{fontSize: {sm:'12px', xs:'12px'}}}>Use with wallet balance</Typography>
                  </Box>
                </Box>
              </Box>
                            <Box sx={{ mt: 4, transition:'0.2s all linear' }}>
                {
                  paymentMethod === "Card" ? (
    
                  <PaystackPaymentButton
                  first_name={userDetails?.first_name}
                  last_name={userDetails?.last_name}
                  email={userDetails?.email}
                  amount={amount}
                  onSuccessAction={handleCreateReservation}
                  label={"Make Payment"}
                  />
                  ):(
   <Button
                disabled={!amount}
                  variant="contained"
                  fullWidth
                  sx={{ py: 2, borderRadius: "12px" }}
                >
                  Complete Reservation
                </Button>
                  )
                }
             

    
              </Box>
                </>
              )
            }
{
  !amount  && (

               <Box sx={{ mt: 4, transition:'0.2s all linear' }}>
                   <Button
                disabled={!userDetails?.first_name || !userDetails?.last_name || !userDetails?.email}
                  variant="contained"
                  onClick={handleCreateReservation2}
                  fullWidth
                  sx={{ py: 2, borderRadius: "12px" }}
                >
                  Complete Reservation
                </Button>
               </Box>
  )
}


            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontWeight: 700 , fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'}}}>
            Why am I paying a booking deposit?
          </Typography>
          <Typography sx={{ fontWeight: 300, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
            What Is the booking deposit and why does Reisty charge the deposit?
          </Typography>
          <Box
            sx={{ mt: 4, display: "flex", alignItems: "center", columnGap: 3 }}
          >
            <img src={logo} width={100} />
            <Box>
              <Typography>Written by Reisty</Typography>
              <Typography sx={{ fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'}, fontWeight: 300 }}>
                Updated over a week ago
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
              What Is the booking deposit?
            </Typography>
            <Typography sx={{ fontWeight: 300, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
              The booking deposit Is a deposit made on the Reisty platform for
              each diner towards a reservation to their choice restaurant.
              Unlike other booking deposits, this will be deducted from the
              final bill after the reservation has been completed.
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
              Why do we charge this deposit?
            </Typography>
            <Typography sx={{ fontWeight: 300, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
              Our restaurant partners put in a lot of effort in preparation
              ahead of your visit to their establishment and this deposit serves
              as protection against no-shows. This deposit will be credited
              towards the total amount spent on dining during your visit.
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: 500, fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'} }}>
              What if I cancel my booking, What happens to my reservation
              deposit?
            </Typography>
            <Typography sx={{ fontWeight: 300, fontSize: {lg:"12px", md:'12px', sm:'10px', xs:'10px'} }}>
              In the event that you need to cancel your reservation, please
              ensure to do so a minimum of 12 hours before the reservation time.
              Upon cancellation, your reservation deposit will be promptly
              refunded to your wallet. This deposit is conveniently accessible
              and can be utilized for future reservations.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Booking;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednessday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
